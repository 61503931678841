import Service from "../Service";
const resource = "PrfBrixColorCultive/"

export default {
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },
    pagination(obj, requestID) {
        return Service.post(resource + "pagination", obj, {
            params: { requestID: requestID },
        });
    },

};