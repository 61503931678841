<template>
  <v-card>
      <v-row style="margin:auto">
          <v-col>
              <s-crud
                :filter="filter"
                :config="config"
                title="Configuracion de Colores por Cultivo"
                add
                edit
                remove
                @save="save($event)"
                search-input
              >
                <template v-slot:filter>
					<v-container>
						<v-row style="margin-left: 5px" justify="center">
                            <v-col cols="12" lg="3" md="3">
                                <s-select-definition 
                                    clearable
                                    :def="1173"
                                    label="Cultivo"
                                    v-model="filter.TypeCultive"
                                ></s-select-definition>
                            </v-col>
                            <v-col cols="6" md="6" lg="3" >
								<s-select-variety
									clearable
									:cultiveID="filter.TypeCultive"
									label="Variedad"
									full
									v-model="filter.VrtID"
								/>
							</v-col>
                        </v-row>
                    </v-container>
                </template>

                <template scope="props">
                    <v-row justify="center">
                        <v-col cols="6" md="6" lg="3" >
								<s-select-definition
                                    
									:def="1173"
									label="Cultivo"
									v-model="props.item.TypeCultive"
								/>
							</v-col>

							<v-col cols="6" md="6" lg="3" >
								<s-select-variety
                                    
									:cultiveID="props.item.TypeCultive"
									label="Variedad"
									full
									v-model="props.item.VrtID"
								/>
							</v-col>
                        </v-row>
                        <v-row justify="center">

                            <v-col cols="6" md="6" lg="3" >
								<s-text 
                                    label="Color (Hexadecimal)"
                                    v-model="props.item.BxcColorHexadecimal"
                                    :maxlength="6"
                                />
							</v-col>
                            <v-col cols="6" md="6" lg="3" >
								<s-text 
                                    label="Descripcion"
                                    v-model="props.item.BxcColorDescription"
                                />
							</v-col>
                    </v-row>
                </template>

                <template v-slot:SecStatus={row}>
                    <v-chip
                        color="success"
                        x-small
                    >
                        {{row.SecStatus == 1 ? 'Activo': 'Inactivo'}}
                    </v-chip>
                </template>

                <template v-slot:BxcColorHexadecimal={row}>
                    <v-chip
                        :color="'#'+row.BxcColorHexadecimal"
                        x-small
                    >
                        {{row.BxcColorHexadecimal}}
                    </v-chip>
                </template>
              </s-crud>
          </v-col>
      </v-row>
  </v-card>
</template>

<script>
import _sColor from "@/services/FreshProduction/PrfColorService";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
export default {
    components: {
        SSelectVariety
    },
    data (){
        return {
            filter:{VrtID: 1000},
            config: {
                
                model: {
                    BxcID: "ID",
                    SecStatus: "",
                    BxcColorHexadecimal: ""
                },
                service: _sColor,
                headers: [
                    { text: "ID", value: "BxcID"},
                    { text: "Variedad", value: "VrtName"},
                    { text: "Color (Hexadecimal)", value: "BxcColorHexadecimal"},
                    { text: "Descripcion", value: "BxcColorDescription"},
                    { text: "Estado", value: "SecStatus"},
                ]
            },
            item: {},
        }
    },
    methods: {
        save(val){
            if(val.BxcColorDescription.length == 0){
                this.$fun.alert("Ingrese una descripcion", "warning")
                return;
            }

            if(val.BxcColorHexadecimal.length == 0){
                this.$fun.alert("Ingrese un valor hexadecimal", "warning")
                return;
            }

            val.SecStatus = 1
            val.UsrCreateID = this.$fun.getUserID()
            console.log("Guardar",val);
            val.save();
        }
    }
}
</script>

<style>

</style>